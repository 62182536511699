/*font-family: 'Roboto', sans-serif;
font-family: 'Open Sans', sans-serif;*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  font-family: "Source Sans Pro", sans-serif !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", sans-serif !important;
}
/*------------------------
        General-Css
------------------------*/
h2 {
  font-size: 26px;
  margin-bottom: 0;
  font-weight: 600;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Login */
canvas#stars {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.login-wrapper {
  padding: 2rem 0;
  align-items: center;
  position: relative;
  z-index: 1;
  /* background-color: #f1f1f1;
    background: url('../images/5127031.jpg') no-repeat top; */
  background-size: cover;
  position: relative;
  display: grid;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}
.loginBox {
  background: #fff;
  box-shadow: 0 10px 30px 0 rgb(17 17 17 / 9%);
  margin: 0 auto;
  max-width: 530px;
  width: 92%;
  text-align: center;
  border-radius: 0;
  overflow: hidden;
  border-top: 1px solid #e7e7e7;
}
.main-bg {
  background: url("../images/login-bg.jpg") no-repeat top;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  border-radius: 0 0 300px 300px;
  z-index: 0;
  position: relative;
  border-right: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.main-bg:before {
  content: "";
  background: rgb(255 255 255 / 67%);
  position: absolute;
  top: 0;
  min-height: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 0 0 300px 300px;
}
.bottom-content {
  padding: 35px;
}
.form-control {
  font-size: 16px;
  border: none;
  width: 100%;
  padding: 15px 15px;
  margin-bottom: 12px;
  border: 1px solid #dedddd;
  outline: none;
  background: transparent;
}
.block-btn {
  width: 100%;
  padding: 15px 28px;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  border: none;
  background: #a3a784;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 12px;
}
.btn:hover {
  background: #686a57;
}
.login-title {
  padding-top: 1em;
  padding-bottom: 2.8em;
  margin-bottom: 1em;
  font-size: 24px;
  line-height: 30px;
  color: #000;
  font-weight: 700;
}
.user-icon {
  border-radius: 50%;
  margin: 0 auto;
  border-radius: 50%;
  margin: 0 auto;
  background: #fff;
  width: 60px;
  height: 60px;
  border: 4px solid rgb(163 167 132);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #a3a784;
}
.login-logo {
  /* width: 96px;
    padding-top: 2em; */
  width: 200px;
  margin-bottom: 15px;
  padding-top: 2em;
}
.form-control {
  border: 1px solid #a3a784;
  outline: none;
}

/* Header */
.body-padding-top {
  padding-top: 63px;
  margin-left: 290px;
  padding-right: 10px;
  padding-bottom: 40px;
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 2px 8px 0 rgb(17 17 17 / 9%);
  padding: 10px 10px;
  background: #fff;
  z-index: 999;
}
img.header-logo {
  /* width: 60px; */
  width: 160px;
}

/* sidebar */
.sidebar {
  height: calc(100vh - 62px);
  padding: 0;
  align-items: baseline;
  background: #ecf0f1;
  border-right: 1px solid #ccc;
  margin-top: 62px;
  max-width: 90%;
  width: 280px;
  position: fixed;
  left: 0;
  overflow: auto;
}
.sidebar .nav-link {
  padding: 12px 15px !important;
  border-bottom: 1px solid #ccc;
  color: #000;
}
.sidebar .nav-link:hover {
  background: #a3a784;
  color: #fff !important;
}
nav .active {
  background: #a3a784 !important;
  color: #fff !important;
  border: 0;
}
.salon-list .list-group-item:hover {
  background: #a3a784;
  color: #fff !important;
}
/* dashboard */
.page-title {
  color: #a3a784;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 15px;
}
.btn-default {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  background: #a3a784;
  border-radius: 40px;
  padding: 7px 25px;
}
.btn-default:hover {
  background: #686a57;
}
.disabled-control .form-control {
  background-color: transparent;
  opacity: 1;
  border: 0;
  border-bottom: 1px solid #a3a784;
  border-radius: 0;
  pointer-events: none;
}
.disabled-control .input-group-text {
  background: none;
  border: 0;
  border-bottom: 1px solid #a3a784;
  cursor: pointer;
}
.small-control {
  padding: 8px 15px;
}
.checkmark-icon {
  background: #a3a784;
  color: #fff;
  border-left: 0;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.table-flex {
  display: block;
  width: 100%;
}
table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: border-box !important;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer {
  border-bottom: 0px solid #111 !important;
}
table.dataTable.no-footer {
  border: 1px solid #dee2e6 !important;
  border-top: 0;
}
.table .edit-icon,
.table .delete-icon {
  color: #fff !important;
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 5px 10px;
  width: 32px;
  font-size: 14px;
}
.edit-icon {
  color: #fff;
  background: #a3a784 !important;
  border-color: #a3a784 !important;
}
a.paginate_button.current {
  padding: 0 7px !important;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.tab-box ul.nav-tabs li {
  display: block;
  width: 100%;
}
.tab-box ul.nav-tabs li a {
  color: #000;
  padding: 10px;
  display: block;
  text-decoration: none;
}
.tab-box ul.nav-tabs li a.active {
  color: #a3a784;
  border-bottom: 2px solid #a3a784;
}
.tab-box .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
.border-left-box {
  border-right: 1px solid #ccc;
}
.tab-box .tab-in ul li {
  display: inline-block;
  width: auto;
}
.tab-box .tab-in ul li a {
  padding: 10px 20px;
}
.tab-box .tab-in .tab-content {
  margin-top: 20px;
}
.glass-list {
  border: 2px solid #a3a784;
  padding: 5px;
  text-align: center;
  margin-bottom: 30px;
}
.glass-list img.glass-img {
  max-width: 140px;
  width: 100%;
  margin: 0 auto;
}
.glass-list-icon {
  position: absolute;
  left: 16px !important;
  bottom: 36px !important;
}
.glass-list-icon a {
  background-color: #bb1e1e;
  padding: 7px 10px;
  color: #fff;
  font-size: 16px;
}
.tab-box.tab-box-vertical ul.nav-tabs li {
  display: inline-block;
  width: auto;
}
.tags-list-img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.search-input label {
  width: 100%;
}
.search-input input {
  width: 100%;
  border-radius: 60px;
  border: 1px solid #ccc;
  padding: 7px 10px;
  border-color: #a3a784 !important;
  outline: none !important;
}
.checkbox-box {
  margin-top: 20px;
}
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #a3a784;
  border: 1px solid #a3a784;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox-box-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.images-btn button {
  border: 0;
  background-color: #a3a784;
  padding: 7px 10px;
  color: #fff;
  cursor: pointer;
}
.images-btn button:hover {
  background-color: #7a7d5e;
}
.images-btn button + button {
  float: right;
}
.tags-list-img {
  position: relative;
  margin-bottom: 30px;
}
.images-btn {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.invalid-feedback {
  text-align: left !important;
}

.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 5px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 500px;
  line-height: 22px;
  cursor: text;
  width: 100%;
  height: 36px;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
  margin-right: 2px;
  color: white;
  padding: 2px 6px 4px;
  border-radius: 4px;
}
.bootstrap-tagsinput .tag span {
  cursor: pointer;
  margin: 0 0 0 5px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.label-info {
  background-color: #a3a784;
}
.old-tags-list {
  background-color: #a3a784;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 30px;
  color: #fff;
  margin: 0 10px 10px 0;
}
.old-tags-list span {
  margin: 0 0 0 5px;
  cursor: pointer;
}
.tags-add-btn {
  margin-top: 50px;
}
.old-tags-title {
  font-size: 18px;
  margin-bottom: 8px;
}
.images-checkbox-box .checkbox-box {
  width: 100%;
  margin: 0;
}
.images-checkbox-box .checkbox-box label.container {
  display: inline-block;
  width: auto;
}
.imageBox {
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  overflow: hidden;
  max-height: 500px;
}
.imageBox img {
  width: 100%;
  object-fit: contain;
  height: 100%;
  object-position: top;
}

.input-group .form-check + .form-check {
  margin: 0 0 0 20px;
}
.up-load-img-box {
  position: relative;
}
.up-load-img-box input {
  width: 120px;
  height: 120px;
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.up-load-img-box img {
  width: 120px;
  height: 120px;
  cursor: pointer;
}

.images-checkbox-box .checkbox-box {
  width: 100%;
  margin: 0;
}
.images-checkbox-box .checkbox-box label.container {
  display: inline-block;
  width: auto;
}

.images-radio-box [type="radio"]:checked,
.images-radio-box [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.images-radio-box [type="radio"]:checked + label,
.images-radio-box [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.images-radio-box [type="radio"]:checked + label:before,
.images-radio-box [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 100%;
  background: #fff;
}
.images-radio-box [type="radio"]:checked + label:after,
.images-radio-box [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #a3a784;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.images-radio-box [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.images-radio-box [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.images-radio-box p {
  margin: 0 15px 0 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: transparent !important;
  border: 1px solid #c7c7c7 !important;
  color: #c7c7c7 !important;
}
.dataTables_wrapper .dataTables_paginate a.paginate_button.current {
  background: #a3a784 !important;
  border-color: #a3a784 !important;
  color: #fff !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  border: 0px solid #c7c7c7 !important;
}
.product-img {
  /* object-fit: contain !important; */
  /* border: 4px solid #f1f1f1; */
  padding: 10px;
  border-radius: 10px;
  /* height: 200px !important; */
}
.product-img-main {
  height: 350px !important;
}
.flex-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-radio
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before,
.flex-radio
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #a3a784;
  height: 35px;
  width: 35px;
  border-radius: 2px;
}
.flex-radio .custom-control {
  height: 35px;
  width: 35px;
  padding: 0;
  margin-top: -1px;
}
.flex-radio .custom-control-label {
  cursor: pointer !important;
}
.flex-radio .custom-control-label::after {
  background: none !important;
  height: 35px;
  width: 35px;
  border-radius: 2px;
}
.flex-radio .custom-radio .custom-control-label::before,
.flex-radio .custom-checkbox .custom-control-label::before {
  background-color: transparent;
  border: 2px solid #a3a784;
  height: 35px;
  width: 35px;
  border-radius: 2px;
}
.flex-radio .custom-radio .custom-control-label span,
.flex-radio .custom-checkbox .custom-control-label span {
  position: absolute;
  left: 0;
  top: 4px;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-radio
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label
  span {
  color: #fff;
}
.flex-radio .radio-title {
  font-size: 14px;
  width: 104px;
}
.flex-checkbox .radio-title {
  width: auto;
  font-weight: 700;
}
.filter-button {
  position: absolute;
  right: 10px;
  top: 12px;
  width: 50px;
  padding: 7px;
}
.flex-radio.flex-radio-lg
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before,
.flex-radio.flex-radio-lg .custom-control,
.flex-radio.flex-radio-lg .custom-control-label::after,
.flex-radio.flex-radio-lg .custom-radio .custom-control-label::before {
  width: 65px;
}
.flex-checkbox.flex-checkbox-lg
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::before,
.flex-checkbox.flex-checkbox-lg .custom-control,
.flex-checkbox.flex-checkbox-lg .custom-control-label::after,
.flex-checkbox.flex-checkbox-lg .custom-checkbox .custom-control-label::before {
  width: 65px;
}
.tags-list-img {
  height: 220px;
  border: 1px solid #ccc;
  overflow: hidden;
}
.tags-list-img img {
  height: 220px;
  object-fit: contain;
}
.product-img-main img {
  height: 350px;
  object-fit: contain;
}
/* .product-scroll{
    max-height: 350px;
    overflow: auto;
    padding-right: 10px;
    height: 100%;
} */
.product-scroll {
  max-height: 350px;
  overflow: hidden auto;
  padding-right: 10px;
  height: 100%;
}
.product-scroll::-webkit-scrollbar {
  width: 1em;
}

.product-scroll::-webkit-scrollbar-track {
  border: 1px solid #898989;
}

.product-scroll::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
.primary-color {
  color: #a3a784 !important;
}
.cursor {
  cursor: pointer;
}
@media (max-width: 1199px) {
  table.dataTable thead .sorting {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .sidebar {
    transform: translateX(-300px);
    z-index: 111;
    transition: all 0.3s;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  .sidebar.opensidebar {
    transform: translateX(0px);
    transition: all 0.3s;
  }
  .sidebar .navbar-collapse {
    display: block;
  }
  .body-padding-top {
    margin-left: 0;
    padding-top: 60px;
  }
  .header-logo {
    width: 120px !important;
  }
}

@media (max-width: 767px) {
  .imageBox {
    border: 2px solid #e7e7e7;
    border-radius: 10px;
    overflow: hidden;
    max-height: 300px;
  }
  .product-img-main {
    height: 250px !important;
  }
  .product-img-main img {
    height: 250px !important;
  }
}
@media (max-width: 575px) {
  .tab-content {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
  }
  .health-grid-table,
  .health-grid-table thead,
  .health-grid-table tbody,
  .health-grid-table th,
  .health-grid-table td,
  .health-grid-table tr {
    display: block;
  }
  .health-grid-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .health-grid-table tr {
    border: 1px solid #ccc;
  }
  .health-grid-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-top: 40px !important;
  }
  .health-grid-table td:before {
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 10px;
    white-space: nowrap;
    background: #e2e2e2;
    width: 100%;
    color: #000;
    padding: 5px;
    font-weight: bold;
  }
  .health-grid-table td:nth-of-type(1):before {
    content: "PROBLEM NAME";
  }
  .health-grid-table td:nth-of-type(2):before {
    content: "CURATIVE THERAPY";
  }
  .health-grid-table td:nth-of-type(3):before {
    content: "PRODUCTS";
  }
  .health-grid-table td.mb-pt-0 {
    padding-top: 0 !important;
    text-align: left !important;
  }
  .filter-sidebar {
    position: fixed;
    top: 72px;
    background: #fff;
    bottom: 0;
    z-index: 999;
    width: 300px;
    right: 0;
    box-shadow: 3px 9px 13px #ccc;
    transform: translateX(340px);
    transition: all 0.3s;
    overflow: auto;
    padding-bottom: 20px;
  }
  .filter-sidebar.open-filter {
    transform: translateX(0px);
    transition: all 0.3s;
  }
}
.form-control:focus {
  box-shadow: 0 0 0 0;
  border-color: #a3a784 !important;
  outline: none !important;
}
.dropdown-menu a:active {
  background-color: #f4f4f4 !important;
}
.btn-default {
  word-break: break-word;
  white-space: pre-wrap;
}
.loader-class{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(23 23 23 / 71%);
  z-index: 999;
}
