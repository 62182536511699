/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
.autocomplete-container .input-container input {
	border: 1px solid #ccc !important;
	border-radius: 5px;
}
.autocomplete-container {
	box-shadow: 0 0 0 !important;
}
.custom-error-star {
	width: 100%;
	padding-left: 5px;
	margin-top: 0.25rem;
	font-size: 100%;
	font-weight: bold;
	color: #dc3545;
}
label.container {
	overflow-wrap: break-word;
	width: calc(100% + 20px);
}
.checkbox_scroll {
	overflow: hidden auto !important;
}
.dropdown-list .item2 .multiselect-item-checkbox {
	width: 100%;
}
.dropdown-list .item2 .multiselect-item-checkbox div::before {
	width: 100%;
	/* background: #a3a784 !important; */
	border: 2px solid #a3a784 !important;
}
.dropdown-list .item2 .multiselect-item-checkbox input[type=checkbox]:checked +  div::before {
	background: #a3a784 !important;
}
.multiselect-dropdown div .dropdown-btn .selected-item {
	max-width: 200px !important;
	border: 1px solid #a3a784 !important;
	background: #a3a784 !important;
}
.multi-select-error {
	border-color: #dc3545 !important;
}
.dropdown-list .item1 .filter-textbox {
	width: 100%;
}
#customFields .addCF {
    background-color: #000;
    font-size: 20px;
    color: #fff;
    padding: 6px 16px;
    line-height: inherit;
    display: inline-block;
}
#customFields .addCF:hover {
    text-decoration: none;
    background-color: #484848;
}
#customFields .remCF {
    background-color: #bb1414;
    font-size: 16px;
    color: #fff;
    padding: 9px 17px;
    line-height: inherit;
    display: inline-block;
}
#customFields .remCF:hover {
    text-decoration: none;
    background-color: #920a0a;
}
/* .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
	color:black !important;
} */
.dataTables_wrapper .dataTables_paginate a.paginate_button:hover{
	background: #a3a784 !important;
    border-color: #a3a784 !important;
    color: #fff !important;
} 

.success a,.success a:hover,.success a:active,.success a:focus{color:#fff;}

.table{
    min-width: 550px;
}
.table thead{
    background-color: #f1f1f1;
}
.customer-detail{padding:40px 0}
.enhance{margin-top:40px}
.enhance_heading .title,.enhance_detail .enhance_text{margin-left:10px}
.slick-slider {
    width: 90%;
    margin: auto;
    background: #000;
  }
  
  .nav-btn {
    height: 47px;
    position: absolute;
    width: 26px;
    cursor: pointer;
    top: 100px !important;
  }
  
  .prev-slide.slick-disabled,
  .next-slide.slick-disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  
  .prev-slide {
    background: url(assets/images/prev.png) no-repeat scroll 0 0;
    left: -33px;
  }
  
  .next-slide {
    background: url(assets/images/next.png) no-repeat scroll -24px 0px;
    right: -33px;
  }
  
  .prev-slide:hover {
    background-position: 0px -53px;
  }
  
  .next-slide:hover {
    background-position: -24px -53px;
  }
  .carousel-slide{
    width:25% !important
  }

  .flex-radio .custom-radio .custom-control-label span, .flex-radio .custom-checkbox .custom-control-label.input-tab span {
    position: absolute;
    left: -10px !important;
    top: 20px !important;
}
  
@media (max-width: 767px){
    .table th{
        padding: 10px 8px;
        font-size: 12px;
    }
    .table td{
        padding: 10px 8px;
        font-size: 13px;
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
